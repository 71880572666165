<template>
  <div class="sizeyou-route h-100">
    <div class="error h-100" v-if="$route.query.error || sizeYouError">
      <div class="row h-100 justify-content-center align-items-center">
        <div class="col d-flex flex-wrap justify-content-center align-items-center">
          <div class="text-center text-danger">
            <h2>{{ $t('SIZEYOU_ERROR_TITLE') }}</h2>
            {{ $t('SIZEYOU_ERROR_DESC') }}
          </div>
        </div>
      </div>
    </div>
    <div
      class="success"
      v-if="!sizeYouError && !$route.query.error && this.$route.query.code && this.$route.query.state"
    >
      <div class="b2bForm" v-if="$route.params.type === 'b2b'">
        <div class="row mt-5">
          <div class="col-12">
            <form autocomplete="off" novalidate @submit.prevent="sendRequest()">
              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <input
                      type="text"
                      :class="{ 'is-invalid': errors.customer }"
                      class="form-control"
                      :placeholder="$t('SIZEYOU_USERNAME')"
                      v-model="customer"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 text-right">
                  <button type="submit" :disabled="isSizeYouFetching" class="submit-btn btn btn-primary">
                    {{ $t('ACTION_SEND') }}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Loader v-if="isSizeYouFetching || $route.params.type === 'b2c'" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Loader from '@/components/Loader.vue'

export default {
  name: 'sizeyou',
  data() {
    return {
      customer: '',
      errors: {}
    }
  },
  computed: {
    ...mapGetters(['isSizeYouFetching', 'sizeYouParams', 'sizeYouError'])
  },
  components: {
    Loader
  },
  mounted() {
    if (this.$route.params.type === 'b2c') {
      this.sendRequest()
    }
  },
  methods: {
    validateCustomer() {
      this.errors = {}
      if (!this.customer) {
        this.errors.customer = true
      }
    },
    sendRequest() {
      if (this.$route.params.type === 'b2b') {
        this.validateCustomer()
      }
      const type = this.$route.params.type
      const redirect = window.location.origin + this.$router.resolve({ name: 'sizeYou', params: { type } }).href
      this.$store.dispatch('SIZEYOU_GET_PARAMS', type).then(() => {
        const data = {
          apiKey: this.sizeYouParams.apiKey,
          code: this.$route.query.code,
          state: this.$route.query.state,
          redirectUri: redirect
        }
        if (this.$route.params.type === 'b2b') {
          data.customer = this.customer
        }
        this.$store
          .dispatch('SIZEYOU_POST_USER', {
            url: this.sizeYouParams.proxyUrl + '/measurement/' + type,
            data
          })
          .then(measures => {
            window.parent.postMessage({ type: 'SIZEYOU', measures }, '*')
          })
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.sizeyou-route {
  background: #fff;
  border: 4px solid $c-blue;
}
.b2bForm {
  background: $c-white;
  padding: 30px;

  .form-control {
    color: $c-grey3;
    font-size: 16px;
    background: $c-grey2;
    border: none;
    border-radius: 0;
    padding-right: 0;
    padding-top: 8px;
    &.is-invalid {
      border: 1px solid $c-error !important;
    }
  }

  .submit-btn {
    text-transform: uppercase;
    background: $c-blue;
    border: none;
    border-radius: 0;
    width: 100%;
  }
}
</style>
